// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-layout {
    display: flex;
  }
  
  .sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: white;
    height: 100vh;
    padding: 20px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 15px 0;
  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
  }
  
  .main-content {
    flex: 1 1;
    background-color: #ecf0f1;
    padding: 20px;
  }
  
  .header {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .content-area {
    margin-top: 20px;
  }
  
  .requests-page table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .requests-page th, .requests-page td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .requests-page th {
    background-color: #f4f4f4;
  }

  .components{
    align-content: space-between;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/style/DashboardLayout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,qBAAqB;EACvB;;EAEA;IACE,SAAO;IACP,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,4BAA4B;EAC9B","sourcesContent":[".dashboard-layout {\n    display: flex;\n  }\n  \n  .sidebar {\n    width: 250px;\n    background-color: #2c3e50;\n    color: white;\n    height: 100vh;\n    padding: 20px;\n  }\n  \n  .sidebar ul {\n    list-style: none;\n    padding: 0;\n  }\n  \n  .sidebar ul li {\n    margin: 15px 0;\n  }\n  \n  .sidebar ul li a {\n    color: white;\n    text-decoration: none;\n  }\n  \n  .main-content {\n    flex: 1;\n    background-color: #ecf0f1;\n    padding: 20px;\n  }\n  \n  .header {\n    background-color: #3498db;\n    color: white;\n    padding: 10px 20px;\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .content-area {\n    margin-top: 20px;\n  }\n  \n  .requests-page table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n  }\n  \n  .requests-page th, .requests-page td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .requests-page th {\n    background-color: #f4f4f4;\n  }\n\n  .components{\n    align-content: space-between;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
