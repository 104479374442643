import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";


const socket = io("https://api.fleet.scidar.org");

const PassengerRequests = () => {
  const [requests, setRequests] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [newRequest, setNewRequest] = useState({
    passengerName: "",
    type: "Ride Request",
    project: "",
    description: "",
  });

  useEffect(() => {
    const loadNotifications = async () => {
      try {
        const data = await fetchNotifications();
        setNotifications(data);
      } catch (error) {
        console.error("Error loading notifications:", error);
      }
    };

    loadNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        "https://api.fleet.scidar.org/api/notifications"
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching notifications:", error);
      throw error;
    }
  };

  const handleCreateRequest = () => {
    console.log("Creating request with data:", newRequest); // Log the data before making the request
    fetch("https://api.fleet.scidar.org/api/create-request", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newRequest),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          alert("Request created and notifications sent.");
          setRequests([...requests, data.request]);
          setNewRequest({
            passengerName: "",
            type: "Ride Request",
            project: "",
            description: "",
          });
        } else {
          console.error("Failed to create request:", data.message);
          alert(`Failed to create request: ${data.message}`);
        }
      })
      .catch((err) => {
        console.error("Error creating request:", err);
        alert(`Error creating request: ${err.message}`);
      });
  };

  // const handleApprove = (requestId) => {
  //   fetch(`http://172.16.3.163/api/requests/${requestId}/approve`, { method: 'POST' })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.success) {
  //         alert('Request approved and notification sent to driver.');
  //         setRequests(prevRequests => prevRequests.map(req =>
  //           req.id === requestId ? { ...req, status: 'Approved' } : req
  //         ));
  //       } else {
  //         console.error('Failed to approve the request:', data.message);
  //         alert(`Failed to approve the request: ${data.message}`);
  //       }
  //     })
  //     .catch(err => {
  //       console.error('Error approving request:', err);
  //       alert(`Error approving request: ${err.message}`);
  //     });
  // };

  return (
    <div>
      <h2>Passenger Requests</h2>
      <h3>Create New Request</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleCreateRequest();
        }}
      >
        <label>
          Passenger Name:
          <input
            type="text"
            value={newRequest.passengerName}
            onChange={(e) =>
              setNewRequest({ ...newRequest, passengerName: e.target.value })
            }
            required
          />
        </label>
        <label>
          Project:
          <input
            type="text"
            value={newRequest.project}
            onChange={(e) =>
              setNewRequest({ ...newRequest, project: e.target.value })
            }
            required
          />
        </label>
        <label>
          Trip Description:
          <textarea
            value={newRequest.description}
            onChange={(e) =>
              setNewRequest({ ...newRequest, description: e.target.value })
            }
            required
          />
        </label>
        <button type="submit">Create Request</button>
      </form>
      <h3>Requests</h3>
      <table>
        <thead>
          <tr>
            <th>Passenger Name</th>
            <th>Request Type</th>
            <th>Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request) => (
            <tr key={request.id}>
              <td>{request.passengerName}</td>
              <td>{request.type}</td>
              <td>{request.date}</td>
              <td>{request.status}</td>
              <td>
                {/* {request.status === 'Pending' && (
                  <button onClick={() => handleApprove(request.id)}>Approve</button>
                )} */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>Notifications</h3>
      <ul>
        {notifications.map((notification) => (
          <li key={notification.id}>
            {notification.message} - {notification.dateSent}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PassengerRequests;
