import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/pages/dashboard";
import PassengerRequests from "./components/pages/passenger";
import DriverNotifications from "./components/pages/driver"; // Create similarly to PassengerRequests
import Layout from "./components/layout";

const App = () => {
  return (
    <Router>
      <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/requests" element={<PassengerRequests />} />
        <Route path="/notifications" element={<DriverNotifications />} />
        <Route path="/settings" element={<h2>Settings</h2>} />
      </Routes>
      </Layout>
    </Router>
  );
};

export default App;
