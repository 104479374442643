import React from 'react';
import Sidebar from './sidebar';
import Header from './header';
import './style/DashboardLayout.css' // For basic styling


const Layout = ({ children }) => {
  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content">
        <Header />
        <div className="content-area">
        {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
