import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";
import { Notification } from "./Notification";

const DriverNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const loadNotifications = async () => {
      try {
        const data = await fetchNotifications();
        setNotifications(data);
      } catch (error) {
        console.error("Error loading notifications:", error);
      }
    };

    loadNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        "https://api.fleet.scidar.org/api/notifications"
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching notifications:", error);
      throw error;
    }
  };

  return (
    <div className="notifications-page">
        <h2>Driver Notifications</h2>
        <table>
          <thead>
            <tr>
              <th>Driver Name</th>
              <th>Notification</th>
              <th>Date Sent</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                message={notification.message}
                user={notification.driverName}
                date={notification.dateSent}
              />
            ))}
          </tbody>
        </table>
    </div>
  );
};

export default DriverNotifications;
